<template>
  <div class="studio-tag-scroll-box studio-scrollbar-4 min-h-80">
    <div v-if="!isDeletableTag" class="flex flex-wrap gap-8 py-4">
      <checkbox-buttons :disabled="disabled" :max="maxTag" :name="name" :options="tagList" />
    </div>
    <template v-else>
      <sortable
        v-if="draggable"
        :key="chipList.length"
        :list="renderChipList"
        itemKey="id"
        :options="options"
        class="flex flex-wrap gap-8 py-4"
      >
        <template #item="{ element, index }">
          <div class="draggable">
            <s-chips
              :key="`${element}-${index}`"
              variant="fill"
              size="md"
              class="rounded-full"
              hasCloseIcon
              :isDisabled="disabled"
              @close="onChipRemove(element.value)"
            >
              <slot v-if="hasSlot('chipSlot')" name="chipSlot" :value="element.label"></slot>
              <template v-else>
                {{ $t(element.label) }}
              </template>
            </s-chips>
          </div>
        </template>
      </sortable>

      <div v-else class="flex flex-wrap gap-8 py-4">
        <s-chips
          v-for="(chip, index) in renderChipList"
          :key="`${chip}-${index}`"
          variant="fill"
          size="md"
          class="rounded-full"
          hasCloseIcon
          :isDisabled="disabled"
          @close="onChipRemove(chip.value)"
        >
          <slot v-if="hasSlot('chipSlot')" name="chipSlot" :value="chip"></slot>
          <template v-else>
            {{ $t(chip.label) }}
          </template>
        </s-chips>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { SortableOptions } from 'sortablejs';
import type { AutoScrollOptions } from 'sortablejs/plugins';
import { Sortable } from 'sortablejs-vue3';
import { useFieldValue, useSetFieldValue } from 'vee-validate';
import { computed, ref, toRefs, useSlots, watch } from 'vue';

import CheckboxButtons from '@/components/validation/checkbox-buttons.vue';
import type { FormOption } from '@/types/common/form.type';

const props = withDefaults(
  defineProps<{
    tagList: FormOption<string>[];
    hasLimit?: boolean;
    maxTag?: number;
    isDeletableTag?: boolean;
    name: string;
    draggable?: boolean;
    disabled?: boolean;
    isTagAlwaysShown?: boolean; // If true, all tags in labelList will be shown even if they are not in tagList
    labelList?: FormOption<string>[];
  }>(),
  {
    disabled: false,
    maxTag: 100,
    isTagAlwaysShown: false,
    labelList: () => []
  }
);

const emits = defineEmits<{
  remove: [v: string];
}>();

const { tagList } = toRefs(props);

const chipList = useFieldValue<string[]>(props.name);

const reserveList = ref<FormOption<string>[]>([]);

const renderChipList = computed<FormOption<string>[]>(() => {
  const list: FormOption<string>[] = reserveList.value.filter((tag: FormOption<string>) =>
    chipList.value?.includes(tag.value)
  );

  if (props.isTagAlwaysShown) {
    const notIncluded = props.labelList.filter(
      (tag: FormOption<string>) => !list?.find((t: FormOption<string>) => t.value === tag.value)
    );

    return list.concat(notIncluded);
  }

  return list;
});

const setChipList = useSetFieldValue<string[]>(props.name);

const options = computed<SortableOptions | AutoScrollOptions>(() => {
  return {
    draggable: '.draggable',
    animation: 150,
    easing: 'cubic-bezier(1, 0, 0, 1)',
    ghostClass: 'ghost',
    dragClass: 'drag',
    scroll: true,
    forceFallback: true,
    bubbleScroll: true,
    swapThreshold: 0.65,
    disabled: props.disabled
  };
});

const slots = useSlots();
const hasSlot = (name: string) => {
  return !!slots[name];
};

const onChipRemove = (chip: string) => {
  if (!chipList.value) {
    return;
  }
  const foundTag = chipList.value.find((c: string) => c === chip);

  if (!foundTag) {
    return;
  }

  emits('remove', chip);

  setChipList(chipList.value.filter((c: string) => c !== foundTag));
};

watch(
  () => tagList.value,
  () => {
    const flattenReserveList = reserveList.value.map((tag: FormOption<string>) => tag.value);

    const diffList = tagList.value.filter(
      (tag: FormOption<string>) => !flattenReserveList.includes(tag.value)
    );

    reserveList.value.push(...diffList);
  },
  { immediate: true }
);
</script>
